import React, { useEffect, useState } from "react";
import Locked from "../../assets/images/locked.svg";
import Percent from "../../assets/images/percent.svg";
import Stakers from "../../assets/images/stakers.svg";
import PoolValue from "../../assets/images/pool-value.svg";
import Price from "../../assets/images/price.svg";
import LockedLight from "../../assets/images/locked_light.svg";
import PercentLight from "../../assets/images/percent_light.svg";
import StakersLight from "../../assets/images/stakers_light.svg";
import PoolValueLight from "../../assets/images/pool-value_light.svg";
import PriceLight from "../../assets/images/price_light.svg";
import {
  APY,
  EarlyFees,
  FathomLink,
  Maturity,
  MaturityDays,
  StakingSD,
  Symbol,
  USDTTokenAddress,
  apothemARPC,
  apothemRPC,
  mainnetARPC,
  mainnetRPC,
  maxStake,
  minStake,
  stakingContractAddress,
  stakingContractAddressAPO,
  stakingContractBlockNumber,
  tokenContractAddress,
  tokenContractAddressAPO,
  tokenPairAddress,
} from "../../helpers/data";
import {
  APIURL,
  axiosInstance,
  numberToReadable,
} from "../../helpers/constant";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";
import xrc20ABI from "../../wallet/TokenAbi.json";
import stakingABI from "../../wallet/StakingAbi.json";
import { toast } from "react-toastify";
import { toastTime } from "../../helpers/constant";
import { useAppContext } from "../../AppContext";
import { ethereumProvider } from "../../wallet/connectors";

const Staking = () => {
  const { pool } = useParams();
  const { active, account, library, chainId } = useWeb3React();
  const { walletAddress, chain, connected, connection } = useAppContext();
  const [FXDBalance, setFXDBalance] = useState(0);
  const [allowed, setAllowed] = useState(0);
  const [amount, setAmount] = useState(0);
  const [web3, setWeb3] = useState();
  const [tokenContractInst, setTokenContractInst] = useState(null);
  const [tokenContractInstARPC, setTokenContractInstARPC] = useState(null);
  const [stakingContractInst, setStakingContractInst] = useState(null);
  const [stakingData, setStakingData] = useState([]);
  const [statementData, setStatementData] = useState([]);
  const [stakingStatus, setStakingStatus] = useState(false);
  const [totalValueLocked, setTotalValueLocked] = useState(0);
  const [numberofStakers, setNumberofStakers] = useState(0);
  const [FXDPrice, setFXDPrice] = useState(0);
  const [updateApproval, setUpdateApproval] = useState(false);
  const [updateStaking, setUpdateStaking] = useState(false);
  const [updateBalance, setUpdateBalance] = useState(false);
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);
  const [apy, setApy] = useState(0);
  const [totalPoolValue, setTotalPoolValue] = useState();
  const [availablePoolValue, setAvailablePoolValue] = useState();
  const [poolDetails, setPoolDetails] = useState({});
  const [poolTokenContractAddress, setPoolTokenContractAddress] = useState('');
  const [poolStakingContractAddress, setPoolStakingContractAddress] = useState('');
  //const [expiryDate, setExpiryDate] = useState();
  
  const updateFXDPrice = async () => {
    const web3 = new Web3(new Web3.providers.HttpProvider(mainnetRPC));
    const FXDContract = new web3.eth.Contract(xrc20ABI, tokenContractAddress);
    const USDTContract = new web3.eth.Contract(xrc20ABI, USDTTokenAddress);
    let bal = await FXDContract.methods.balanceOf(tokenPairAddress).call();
    const tokenBal = parseFloat(
      parseFloat(web3.utils.fromWei(bal, "ether")).toFixed(4)
    );
    bal = await USDTContract.methods.balanceOf(tokenPairAddress).call();
    const usdtBal = parseFloat(parseFloat(bal / 10 ** 6).toFixed(4));
    setFXDPrice(parseFloat(parseFloat(usdtBal / tokenBal).toFixed(4)));
  };

  // updateFXDPrice();
  // setInterval(() => {
  //   updateFXDPrice();
  // }, 300000);

  useEffect(() => {
    let fetchPoolDetails = async () => {
      const data = await axiosInstance.get(
        `/pools/${pool}`
      );
      setPoolDetails(data.data);
      setPoolTokenContractAddress(data.data.tokenAddress);
      setPoolStakingContractAddress(data.data.poolAddress);
      setTotalPoolValue(data.data.poolValue);
      setAvailablePoolValue(data.data.availableValue);
      setApy(data.data.apyRate);
    };

    fetchPoolDetails();
  }, [pool]);

  useEffect(() => {
    if(poolTokenContractAddress!='' && poolStakingContractAddress!=''){
      if (connected && library) {
        const web3Inst = new Web3(library.provider);
        setWeb3(web3Inst);
        setTokenContractInst(
          new web3Inst.eth.Contract(
            xrc20ABI,
            poolTokenContractAddress
          )
        );
        setStakingContractInst(
          new web3Inst.eth.Contract(
            stakingABI,
            poolStakingContractAddress
          )
        );
        const newInst = new Web3(
          new Web3.providers.HttpProvider(
            chain === 50 ? mainnetARPC : apothemARPC
          )
        );
        setTokenContractInstARPC(
          new newInst.eth.Contract(
            xrc20ABI,
            poolTokenContractAddress
          )
        );
      } else if (
        localStorage.getItem("wallet") === "walletConnect" &&
        connected
      ) {
        console.log("this is in then wallet block");
        walletConnectionProvider();
      } else {
        const web3Inst = new Web3(new Web3.providers.HttpProvider(mainnetRPC));
        setWeb3(web3Inst);
        setTokenContractInst(
          new web3Inst.eth.Contract(xrc20ABI, tokenContractAddress)
        );
        setStakingContractInst(
          new web3Inst.eth.Contract(stakingABI, poolStakingContractAddress)
        );
        const newInst = new Web3(new Web3.providers.HttpProvider(mainnetARPC));
        setTokenContractInstARPC(
          new newInst.eth.Contract(xrc20ABI, tokenContractAddress)
        );
      }
    }
  }, [connected, chain, library, connection,poolTokenContractAddress,poolStakingContractAddress]);

  const walletConnectionProvider = async () => {
    const walletConnectProvider = await ethereumProvider(
      true,
      process.env.REACT_APP_WALLETCONNECT_ID
    );

    const web3Inst = new Web3(walletConnectProvider);
    setWeb3(web3Inst);

    setTokenContractInst(
      new web3Inst.eth.Contract(
        xrc20ABI,
        poolTokenContractAddress
      )
    );
    setStakingContractInst(
      new web3Inst.eth.Contract(
        stakingABI,
        poolStakingContractAddress
      )
    );
    const newInst = new Web3(
      new Web3.providers.HttpProvider(chain === 50 ? mainnetARPC : apothemARPC)
    );
    setTokenContractInstARPC(
      new newInst.eth.Contract(
        xrc20ABI,
        poolTokenContractAddress
      )
    );
  };

  useEffect(() => {
    let IsMounted = true;
    
    const fetchAccountBalance = async () => {
      try {
        const bal = await tokenContractInst.methods
          .balanceOf(walletAddress)
          .call();
        const tokenBal = parseFloat(
          parseFloat(web3.utils.fromWei(bal + "", "ether")).toFixed(4)
        );
        IsMounted && setFXDBalance(tokenBal);
      } catch (error) {
        console.error("Error fetching account balance:", error);
      }
    };

    const fetchAllowance = async () => {
      try {
        const approved = await tokenContractInst.methods
          .allowance(
            walletAddress,
            poolStakingContractAddress
          )
          .call();
        const approvedTokens = parseFloat(
          parseFloat(web3.utils.fromWei(approved + "", "ether")).toFixed(4)
        );
        IsMounted && setAllowed(approvedTokens);
      } catch (error) {
        console.error("Error fetching allowance:", error);
      }
    };

    async function fetchData() {
      if (walletAddress && library && tokenContractInst && poolTokenContractAddress!='') {
        console.log("Oradosh",poolTokenContractAddress);
        await fetchAccountBalance();
        await fetchAllowance();
      }
    }
    fetchData();
    return () => {
      IsMounted = false;
    };
  }, [
    poolTokenContractAddress,
    allowed,
    library,
    walletAddress,
    chain,
    web3,
    tokenContractInst,
    updateApproval,
    updateStaking,
    updateBalance,
  ]);

  useEffect(() => {
    let isMounted = true;
    async function updateData() {
      console.log("isMounted",isMounted);
      const valueLocked = await stakingContractInst.methods
        .totalStaked()
        .call();
        console.log("valueLocked",valueLocked);
      isMounted &&
        setTotalValueLocked(
          parseFloat(
            parseFloat(web3.utils.fromWei(valueLocked, "ether")).toFixed(4)
          )
        );
      isMounted &&
        setNumberofStakers(
          parseInt(
            await stakingContractInst.methods.getNumberOfStakers().call()
          )
        );
      const interest = parseInt(
        await stakingContractInst.methods.interest().call()
      );
      const interestPrecision = parseInt(
        await stakingContractInst.methods.interestPrecision().call()
      );
      isMounted && setApy(parseFloat(interest / interestPrecision).toFixed(2));
      const minAmount = await stakingContractInst.methods
        .minStakeAmount()
        .call();
      const maxAmount = await stakingContractInst.methods
        .maxStakeAmount()
        .call();
      isMounted &&
        setMin(
          parseFloat(
            parseFloat(web3.utils.fromWei(minAmount, "ether")).toFixed(4)
          )
        );
      isMounted &&
        setMax(
          parseFloat(
            parseFloat(web3.utils.fromWei(maxAmount, "ether")).toFixed(4)
          )
        );
      const maxPoolAmount = parseFloat(
        parseFloat(
          web3.utils.fromWei(
            await stakingContractInst.methods.maxPoolAmount().call(),
            "ether"
          )
        ).toFixed(4)
      );
      isMounted && setTotalPoolValue(maxPoolAmount);
      const pendingPoolAmount = parseFloat(
        parseFloat(
          web3.utils.fromWei(
            await stakingContractInst.methods.pendingPoolAmount().call(),
            "ether"
          )
        ).toFixed(4)
      );
      console.log(pendingPoolAmount);
      let expiryTime =
        parseInt(await stakingContractInst.methods.expiryTime().call()) * 1000;       
      const expired = new Date() >= new Date(expiryTime);
      console.log(expired);
      //setExpiryDate(new Date(expiryTime));
      isMounted && setAvailablePoolValue(expired ? 0 : pendingPoolAmount);
    }
    if (connected && stakingContractInst && poolTokenContractAddress!='') {
      updateData();
    }

    return () => {
      isMounted = false;
    };
  }, [stakingContractInst, web3, updateStaking,poolTokenContractAddress,connected]);

  const ApproveAmount = async () => {
    if (connected) {
      if (amount >= min) {
        if (amount % min !== 0)
          toast.warn(
            `Staking allowed only in multiples of ${min}, so approve accordingly`,
            {
              position: toast.POSITION.TOP_LEFT,
              autoClose: toastTime,
            }
          );
        const approvalAmount = web3.utils.toWei(amount + "", "ether");
        const txdata = tokenContractInst.methods
          .approve(
            poolStakingContractAddress,
            approvalAmount
          )
          .encodeABI();
        const tx = {
          from: walletAddress,
          to: poolTokenContractAddress,
          data: txdata,
        };

        web3.eth.sendTransaction(tx, async (err, hash) => {
          if (err)
            toast.error(err, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: toastTime,
            });
          else {
            toast.success(hash, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: toastTime,
            });
            setTimeout(async () => {
              setUpdateApproval(!updateApproval);
            }, 10000);
          }
          // let interval = setInterval(async () => {
          //   try {
          //     const receipt = await web3.eth.getTransactionReceipt(hash);
          //     console.log("receipt", receipt);
          //     if (receipt !== null) {
          //       if (receipt.status) {
          //         clearInterval(interval);
          //       } else {
          //         clearInterval(interval);
          //       }
          //     }
          //   } catch (e) {
          //     clearInterval(interval);
          //   }
          // }, 5000);
        });
      } else
        toast.error(`Minimum Staking Amount is ${min}`, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: toastTime,
        });
    } else {
      toast.error("Wallet Connection Required to Proceed", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: toastTime,
      });
    }
  };

  const StakeNow = async () => {
    if (connected) {
      if (amount >= min) {
        const stakeAmount = web3.utils.toWei(amount + "", "ether");
        console.log(stakeAmount);
        const txdata = stakingContractInst.methods
          .stake(stakeAmount)
          .encodeABI();
        const tx = {
          from: walletAddress,
          to: poolStakingContractAddress,
          data: txdata,
        };
        console.log(tx);
        web3.eth.sendTransaction(tx, async (err, hash) => {
          if (err) {
            toast.error(err, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: toastTime,
            });
          } else {
            toast.success(hash, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: toastTime,
            });
            setTimeout(async () => {
              setUpdateStaking(!updateStaking);
              setUpdateBalance(!updateBalance);
              setUpdateApproval(!updateApproval);
            }, 10000);
          }
          // let interval = setInterval(async () => {
          //   try {
          //     const receipt = await web3.eth.getTransactionReceipt(hash);
          //     console.log("receipt", receipt);
          //     if (receipt !== null) {
          //       if (receipt.status) {
          //         clearInterval(interval);
          //       } else {
          //         clearInterval(interval);
          //       }
          //     }
          //   } catch (e) {
          //     clearInterval(interval);
          //   }
          // }, 5000);
        });
      } else
        toast.error(`Minimum Staking Amount is ${min}`, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: toastTime,
        });
    } else {
      toast.error("Wallet Connection Required to Proceed", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: toastTime,
      });
    }
  };

  const WithdrawTokens = async () => {
    if (connected) {
        let amounttest = 10;
        const stakeAmount = web3.utils.toWei(amounttest + "", "ether");
        console.log(stakeAmount);
        const txdata = stakingContractInst.methods
          .withdrawTokens(walletAddress,stakeAmount)
          .encodeABI();
          console.log(txdata);
          toast.success(txdata, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: toastTime,
          });
          const tx = {
            from: walletAddress,
            to: poolStakingContractAddress,
            data: txdata,
          };
          console.log(tx);
          web3.eth.sendTransaction(tx, async (err, hash) => {
            if (err) {
              toast.error(err, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: toastTime,
              });
            } else {
              toast.success(hash, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: toastTime,
              });
            }
            // let interval = setInterval(async () => {
            //   try {
            //     const receipt = await web3.eth.getTransactionReceipt(hash);
            //     console.log("receipt", receipt);
            //     if (receipt !== null) {
            //       if (receipt.status) {
            //         clearInterval(interval);
            //       } else {
            //         clearInterval(interval);
            //       }
            //     }
            //   } catch (e) {
            //     clearInterval(interval);
            //   }
            // }, 5000);
          });
    } else {
      toast.error("Wallet Connection Required to Proceed", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: toastTime,
      });
    }
  };

  const withdrawXdc = async () => {
    if (connected) {
        let amounttest = 10;
        const stakeAmount = web3.utils.toWei(amounttest + "", "ether");
        console.log(stakeAmount);
        const txdata = stakingContractInst.methods
          .withdrawXdc(walletAddress,stakeAmount)
          .encodeABI();
          console.log(txdata);
          toast.success(txdata, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: toastTime,
          });
          const tx = {
            from: walletAddress,
            to: poolStakingContractAddress,
            data: txdata,
          };
          console.log(tx);
          web3.eth.sendTransaction(tx, async (err, hash) => {
            if (err) {
              toast.error(err, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: toastTime,
              });
            } else {
              toast.success(hash, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: toastTime,
              });
            }
            // let interval = setInterval(async () => {
            //   try {
            //     const receipt = await web3.eth.getTransactionReceipt(hash);
            //     console.log("receipt", receipt);
            //     if (receipt !== null) {
            //       if (receipt.status) {
            //         clearInterval(interval);
            //       } else {
            //         clearInterval(interval);
            //       }
            //     }
            //   } catch (e) {
            //     clearInterval(interval);
            //   }
            // }, 5000);
          });
    } else {
      toast.error("Wallet Connection Required to Proceed", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: toastTime,
      });
    }
  };

  const setMaxInput = () => {
    allowed > 0
      ? allowed >= maxStake
        ? setAmount(maxStake)
        : allowed >= FXDBalance
        ? setAmount(FXDBalance)
        : setAmount(allowed)
      : FXDBalance >= maxStake
      ? setAmount(maxStake)
      : setAmount(FXDBalance);
  };

  useEffect(() => {
    let isMounted = true;
    async function updateStakingData() {
      try {
        const data = await stakingContractInst.methods
          .stakes(walletAddress)
          .call();
        console.log(data);
        const expiryTime =
          parseInt(await stakingContractInst.methods.expiryTime().call()) *
          1000;
          console.log("expiryTime",expiryTime);
        const canUnstake = new Date() >= new Date(expiryTime);
        console.log("canUnstake",canUnstake)
        const pendingRewards = parseFloat(
          parseFloat(
            web3.utils.fromWei(
              await stakingContractInst.methods.earned(walletAddress).call(),
              "ether"
            )
          ).toFixed(4)
        );
        console.log("pendingRewards",pendingRewards)
        let canWithdrawIn = "";
        if (!data[2] && data[4])
          canWithdrawIn = await stakingContractInst.methods
            .canWithdrawStakeIn(walletAddress)
            .call();
            console.log("canWithdrawIn",canWithdrawIn)
        if (data[3]) {
          const stakingArray = [],
            statementArray = [];
          const startTime = new Date(data[5] * 1000);
          stakingArray.push({
            tenure: Maturity,
            rate: `${apy} %`,
            amount: parseFloat(
              parseFloat(web3.utils.fromWei(data[10] + "", "ether")).toFixed(4)
            ),
            start: startTime,
            end: new Date(
              new Date(startTime.getTime() + MaturityDays * 24 * 60 * 60 * 1000)
            ),
            rewards:
              parseFloat(
                parseFloat(web3.utils.fromWei(data[7], "ether")).toFixed(4)
              ) +
              pendingRewards +
              parseFloat(
                parseFloat(web3.utils.fromWei(data[9], "ether")).toFixed(4)
              ),
            canUnstake: data[2] && canUnstake && !data[4] ? true : false,
            canWithdawStake: data[4]
              ? parseInt(canWithdrawIn) === 0
                ? true
                : false
              : false,
            status: data[2] ? "Staked" : data[4] ? "UnStaked" : "Completed",
          });
          const contractEvents = await stakingContractInst.getPastEvents({
            fromBlock: stakingContractBlockNumber,
            toBlock: "latest",
            filter: { staker: walletAddress },
          });
          console.log("contractEvents",contractEvents)
          let i = 0;
          for (i = 0; i < contractEvents.length; i++) {
            const contractEvent = contractEvents[i];
            const blockNumber = contractEvent.blockNumber;
            const blockDetails = await web3.eth.getBlock(blockNumber);
            const timestamp = blockDetails.timestamp * 1000;
            const balanceBefore = parseFloat(
              parseFloat(
                web3.utils.fromWei(
                  (await tokenContractInstARPC.methods
                    .balanceOf(walletAddress)
                    .call({}, blockNumber - 1)) + "",
                  "ether"
                )
              ).toFixed(4)
            );
            const balanceAfter = parseFloat(
              parseFloat(
                web3.utils.fromWei(
                  (await tokenContractInstARPC.methods
                    .balanceOf(walletAddress)
                    .call({}, blockNumber + 1)) + "",
                  "ether"
                )
              ).toFixed(4)
            );
            if (
              contractEvent.event === "WithdrewStake" &&
              parseFloat(
                parseFloat(
                  web3.utils.fromWei(
                    contractEvent.returnValues.earnings + "",
                    "ether"
                  )
                ).toFixed(4)
              ) > 0
            ) {
              statementArray.push({
                amount: parseFloat(
                  parseFloat(
                    web3.utils.fromWei(
                      contractEvent.returnValues.earnings + "",
                      "ether"
                    )
                  ).toFixed(4)
                ),
                time: new Date(timestamp),
                event: "ClaimedRewards",
                hash: contractEvent.transactionHash,
                balanceBefore,
                balanceAfter,
              });
            }
            statementArray.push({
              amount: parseFloat(
                parseFloat(
                  web3.utils.fromWei(
                    (contractEvent.event === "WithdrewStake"
                      ? contractEvent.returnValues.principal
                      : contractEvent.returnValues.amount) + "",
                    "ether"
                  )
                ).toFixed(4)
              ),
              time: new Date(timestamp),
              event: contractEvent.event,
              hash: contractEvent.transactionHash,
              balanceBefore,
              balanceAfter,
            });
          }
          statementArray.sort(function (a, b) {
            return new Date(a.time) - new Date(b.time);
          });
          console.log("updating state", isMounted);
          if (isMounted) {
            console.log("stakingArray",stakingArray)
            console.log("statementArray",statementArray)
            setStakingData(stakingArray);
            setStatementData(statementArray);
            setStakingStatus(data[2]);
          }
        } else {
          console.log("updating state", isMounted);
          if (isMounted) {
            setStakingData([]);
            setStatementData([]);
            setStakingStatus(false);
          }
        }
      } catch (error) {
        console.error("Error updating staking data:", error);
      }
    }
    if (walletAddress && tokenContractInst && stakingContractInst && poolTokenContractAddress!='' && poolStakingContractAddress!='') {
      updateStakingData();
    }
    return () => {
      isMounted = false;
    };
  }, [
    poolStakingContractAddress,
    poolTokenContractAddress,
    library,
    walletAddress,
    stakingContractInst,
    web3,
    updateStaking,
    connected,
    apy,
    tokenContractInst,
    tokenContractInstARPC,
  ]);

  const claimRewards = async () => {
    if (connected) {
      const txdata = stakingContractInst.methods
        .claimEarned(walletAddress)
        .encodeABI();
      const tx = {
        from: walletAddress,
        to: poolStakingContractAddress,
        data: txdata,
      };

      web3.eth.sendTransaction(tx, async (err, hash) => {
        if (err)
          toast.error(err, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: toastTime,
          });
        else {
          toast.success(hash, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: toastTime,
          });
          setTimeout(async () => {
            setUpdateBalance(!updateBalance);
            setUpdateStaking(!updateStaking);
          }, 10000);
        }
        // let interval = setInterval(async () => {
        //   try {
        //     const receipt = await web3.eth.getTransactionReceipt(hash);
        //     console.log("receipt", receipt);
        //     if (receipt !== null) {
        //       if (receipt.status) {
        //         clearInterval(interval);
        //       } else {
        //         clearInterval(interval);
        //       }
        //     }
        //   } catch (e) {
        //     clearInterval(interval);
        //   }
        // }, 5000);
      });
    }
  };

  const unstake = async () => {
    if (connected) {
      const txdata = stakingContractInst.methods.unstake().encodeABI();
      const tx = {
        from: walletAddress,
        to: poolStakingContractAddress,
        data: txdata,
      };

      web3.eth.sendTransaction(tx, async (err, hash) => {
        if (err)
          toast.error(err, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: toastTime,
          });
        else {
          toast.success(hash, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: toastTime,
          });
          setTimeout(async () => {
            setUpdateBalance(!updateBalance);
            setUpdateStaking(!updateStaking);
          }, 10000);
        }
        // let interval = setInterval(async () => {
        //   try {
        //     const receipt = await web3.eth.getTransactionReceipt(hash);
        //     console.log("receipt", receipt);
        //     if (receipt !== null) {
        //       if (receipt.status) {
        //         clearInterval(interval);
        //       } else {
        //         clearInterval(interval);
        //       }
        //     }
        //   } catch (e) {
        //     clearInterval(interval);
        //   }
        // }, 5000);
      });
    }
  };

  const withdrawStake = async () => {
    if (connected) {
      const txdata = stakingContractInst.methods.withdrawStake().encodeABI();
      const tx = {
        from: walletAddress,
        to: poolStakingContractAddress,
        data: txdata,
      };

      web3.eth.sendTransaction(tx, async (err, hash) => {
        if (err)
          toast.error(err, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: toastTime,
          });
        else {
          toast.success(hash, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: toastTime,
          });
          setTimeout(async () => {
            setUpdateBalance(!updateBalance);
            setUpdateApproval(!updateApproval);
          }, 10000);
        }
        // let interval = setInterval(async () => {
        //   try {
        //     const receipt = await web3.eth.getTransactionReceipt(hash);
        //     console.log("receipt", receipt);
        //     if (receipt !== null) {
        //       if (receipt.status) {
        //         clearInterval(interval);
        //       } else {
        //         clearInterval(interval);
        //       }
        //     }
        //   } catch (e) {
        //     clearInterval(interval);
        //   }
        // }, 5000);
      });
    }
  };

  const renderStakings = stakingData.map((staking, index) => {
    return (
      <tr key={index}>
        <td>{staking.tenure}</td>
        <td>{staking.rate}</td>
        <td>{staking.amount}</td>
        <td>{staking.rewards}</td>
        <td>{new Date(staking.start).toLocaleString()}</td>
        <td>{new Date(staking.end).toLocaleString()}</td>
        <td>
          <div
            className={`label  ${
              staking.status === "UnStaked" ? "label-completed" : "label-active"
            }`}
          >
            {staking.status}
          </div>
        </td>
        <td>
          {staking.canUnstake ? (
            <>
              {/* {staking.rewards > 0 ? (
                <div
                  type="button"
                  className="label label-success"
                  onClick={claimRewards}
                >
                  Claim Rewards
                </div>
              ) : (
                <></>
              )} */}
              {"  "}
              <div
                type="button"
                className="label label-warning"
                onClick={unstake}
              >
                Unstake
              </div>
            </>
          ) : staking.canWithdawStake ? (
            <div
              type="button"
              className="label label-active"
              onClick={withdrawStake}
            >
              Withdraw Stake
            </div>
          ) : (
            <></>
          )}
        </td>
      </tr>
    );
  });

  const renderStatement = statementData.map((statement, index) => {
    return (
      <tr key={index}>
        <td>{new Date(statement.time).toLocaleString()}</td>
        <td>
          <div
            className={`label  ${
              statement.event === "Staked" ||
              statement.event === "ClaimedRewards"
                ? "label-active"
                : "label-completed"
            }`}
          >
            {statement.event === "Staked"
              ? "Stake"
              : statement.event === "ClaimedRewards"
              ? "Rewards"
              : statement.event === "Unstaked"
              ? "Unstake"
              : statement.event === "WithdrewStake"
              ? "Withdraw"
              : statement.event}
          </div>
        </td>
        <td>
          <a
            href={
              (chain === 50
                ? "https://xdc.network/txs/"
                : "https://explorer.apothem.network/txs/") + statement.hash
            }
            target="_blank"
            rel="noreferrer"
          >
            {statement.hash.substring(0, 5)}...
            {statement.hash.substring(statement.hash.length - 5)}{" "}
          </a>
        </td>
        <td>{statement.balanceBefore}</td>
        <td>
          {statement.amount}
          {statement.event === "Staked"
            ? "(Dr.)"
            : statement.event === "ClaimedRewards" ||
              statement.event === "WithdrewStake"
            ? "(Cr.)"
            : ""}
        </td>
        <td className="fw-8">{statement.balanceAfter}</td>
      </tr>
    );
  });

  return (
    <div className="container-fluid">
      <div className="row overflow-block">
        <div className="col-lg-12">
          <div className="overflow-block-bg mb-5">
            <div className="cta-mobile d-flex gap-20">
              <div className="price d-flex gap-6">
                {/* <div className="fs-12 fw-7">FXD PRICE:</div>
                <div className="fs-16 fw-8">$ {FXDPrice}</div> */}
              </div>
              <div className="buy">
                {/* <a
                  href={FathomLink}
                  className="fw-6"
                  target="_blank"
                  rel="noreferrer"
                >
                  BUY FXD
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-lg-12">
          <div className="card-transparent card-block card-stretch card-height mb-3">
            <div className="justify-content-between">
              <h4 className="mb-2">Staking</h4>
              <p>{StakingSD}</p>
            </div>
          </div>
        </div>
        <div className="col-lg-7 col-md-6">
          <div className="card card-block card-stretch card-height iq-welcome">
            <div className="card-body">
              <div className="d-flex flex-wrap align-items-center">
                <div className="staking-block col-lg-12 p-0">
                  <h4 className="mb-4">New Stake</h4>
                  <form className="form-horizontal">
                    <div className="form-body">
                      <div className="form-group row">
                        <div className="col-12">
                          <label className="control-label mb-1">
                            STAKING AMOUNT
                          </label>
                          <div className="input-group border-radius">
                            <input
                              type="number"
                              className="form-control"
                              aria-label="Withdraw Stack"
                              id="withdraw-stack"
                              placeholder={0}
                              onChange={(event) => {
                                setAmount(
                                  event.target.value > maxStake
                                    ? maxStake
                                    : event.target.value
                                );
                              }}
                              value={amount}
                              min={min}
                              max={max}
                              step={1}
                            />
                            <button
                              className="input-group-text"
                              onClick={setMaxInput}
                              type="button"
                            >
                              Max
                            </button>
                          </div>
                          <small className="form-text d-lg-flex d-md-flex d-sm-flex justify-content-between">
                            <div className="available-number">
                              <span className="available-explain">
                                Available:{" "}
                              </span>
                              {FXDBalance} {Symbol}
                            </div>
                            <div className="available-number">
                              <span className="available-explain">
                                Approved:{" "}
                              </span>
                              {allowed} {Symbol}
                            </div>
                          </small>
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-12">
                          <label className="control-label mb-1">
                            STAKING PERIOD
                          </label>
                          <ul
                            className="nav nav-pills pills-dark"
                            id="pills-tab"
                            role="tablist"
                          >
                            <li className="nav-item">
                              <button
                                className="nav-link tenure-btn active"
                                data-toggle="pill"
                                aria-controls="pills-30Days"
                              >
                                {poolDetails.tenure} Month
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="tab-content" id="myTabContent">
                        <div
                          className="tab-pane fade active show"
                          id="period-tab1-pane"
                          role="tabpanel"
                          aria-labelledby="pills-30Days"
                          tabIndex={0}
                        >
                          <div className="staking-info">
                            <div className="row align-items-center g-5">
                              <div className="col-sm-8">
                                <ul className="staking-info-list">
                                  <li className="staking-info-item">
                                    <p className="staking-info-name">
                                      Minimum Staking:{" "}
                                      <span className="staking-info-value">
                                        {min}
                                      </span>
                                    </p>
                                  </li>
                                  <li className="staking-info-item">
                                    <p className="staking-info-name">
                                      Maximum Staking:{" "}
                                      <span className="staking-info-value">
                                        {max}
                                      </span>
                                    </p>
                                  </li>
                                  <li className="staking-info-item">
                                    <p className="staking-info-name">
                                      Early unstake fee:{" "}
                                      <span className="staking-info-value">
                                        {EarlyFees}
                                      </span>
                                    </p>
                                  </li>
                                  {/* <li className="staking-info-item">
                                    <p className="staking-info-name">
                                      Expiry Date:{" "}
                                      <span className="staking-info-value">
                                        {expiryDate}
                                      </span>
                                    </p>
                                  </li> */}
                                </ul>
                              </div>
                              <div className="col-sm-4">
                                <div className="staking-apy">
                                  <p>APY Rate</p>
                                  <h4 className="counter">{apy} % P.A.</h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-group row mt-1 mb-1">
                        <div className="col-12">
                          {allowed < min ? (
                            <button
                              type="button"
                              className="btn btn-staking btn-rounded w-100 px-4"
                              style={{}}
                              onClick={ApproveAmount}
                              disabled={stakingStatus ? "true" : ""}
                            >
                              APPROVE
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="btn btn-staking btn-rounded w-100 px-4"
                              style={{}}
                              onClick={StakeNow}
                              disabled={stakingStatus ? "true" : ""}
                            >
                              STAKE NOW
                            </button>
                          )}
                          {/* <button
                            type="button"
                            className="btn btn-staking btn-rounded w-100 px-4"
                            style={{}}
                            onClick={WithdrawTokens}
                          >
                            Withdraw Token Test
                          </button>
                          <button
                            type="button"
                            className="btn btn-staking btn-rounded w-100 px-4"
                            style={{}}
                            onClick={withdrawXdc}
                          >
                            Withdraw XDC Test
                          </button> */}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-5 col-md-6">
          <div className="card card-block card-stretch card-height">
            <div className="card-body">
              <div className="stats-counter">
                <h4 className="mb-4">Network Stats</h4>
                <div className="stats-counter-item">
                  <img
                    src={PriceLight}
                    alt="price_light"
                    className="light-logo"
                  />
                  <img src={Price} alt="price" className="darkmode-logo" />
                  <div className="info">
                    <div className="counter stats-value">
                      <strong>{totalPoolValue}</strong>
                    </div>
                    <div>Total Pool Value</div>
                  </div>
                </div>
                <div className="stats-counter-item">
                  <img
                    src={PoolValueLight}
                    alt="pool-value_light"
                    className="light-logo"
                  />
                  <img
                    src={PoolValue}
                    alt="pool-value"
                    className="darkmode-logo"
                  />
                  <div className="info">
                    <div className="counter stats-value">
                      <strong>{availablePoolValue}</strong>
                    </div>
                    <div>Available Pool Value</div>
                  </div>
                </div>
                <div className="stats-counter-item">
                  <img
                    src={LockedLight}
                    alt="locked_light"
                    className="light-logo"
                  />
                  <img src={Locked} alt="locked" className="darkmode-logo" />
                  <div className="info">
                    <div className="counter stats-value">
                      <strong>{totalValueLocked}</strong> {Symbol}
                    </div>
                    <div>Total Value Locked</div>
                  </div>
                </div>
                <div className="stats-counter-item">
                  <img
                    src={PercentLight}
                    alt="percent_light"
                    className="light-logo"
                  />
                  <img src={Percent} alt="percent" className="darkmode-logo" />
                  <div className="info">
                    <div className="counter stats-value">
                      <strong>{apy} % P.A.</strong>
                    </div>
                    <div>APY</div>
                  </div>
                </div>
                <div className="stats-counter-item">
                  <img
                    src={StakersLight}
                    alt="Stakers_light"
                    className="light-logo"
                  />
                  <img src={Stakers} alt="Stakers" className="darkmode-logo" />
                  <div className="info">
                    <div className="counter stats-value">
                      <strong>{numberofStakers}</strong>
                    </div>
                    <div>Number of Stakers</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-5">
        <div className="col-lg-12">
          <div className="card-transparent card-block card-stretch card-height mb-3">
            <div className="justify-content-between">
              <h4 className="mb-2">Your Statement</h4>
              <p className="mb-0">
                <span className="fw-8">Note: </span>Staking will be auto-rolled
                over after staking period is over.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="card card-block card-stretch card-height iq-welcome">
            <div className="card-body">
              <div className="d-flex flex-wrap align-items-center">
                <div className="staking-block col-lg-12 p-0">
                  <div className="table-responsive staking-table">
                    <table className="table table-hover mb-0">
                      <thead className="thead-dark">
                        <tr>
                          <th scope="col" className="mw-100">
                            Tenure
                          </th>
                          <th scope="col" className="mw-75">
                            Rate
                          </th>
                          <th scope="col" className="mw-100">
                            Amount (FXD)
                          </th>
                          <th scope="col" className="mw-100">
                            Earned
                          </th>
                          <th scope="col" className="mw-125">
                            Created
                          </th>
                          <th scope="col" className="mw-125">
                            Ending
                          </th>
                          <th scope="col" className="mw-100">
                            Status
                          </th>
                          <th scope="col" className="mw-225">
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="spacer">
                          <td colSpan={100} />
                        </tr>
                        {renderStakings}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="card card-block card-stretch card-height iq-welcome">
            <div className="card-body">
              <div className="d-flex flex-wrap align-items-center">
                <div className="staking-block col-lg-12 p-0">
                  <div className="table-responsive staking-table">
                    <table className="table table-hover mb-0">
                      <thead className="thead-dark">
                        <tr>
                          <th scope="col" className="mw-150">
                            Time
                          </th>
                          <th scope="col" className="mw-125">
                            Description
                          </th>
                          <th scope="col" className="mw-125">
                            Transaction Hash{" "}
                          </th>
                          <th scope="col" className="mw-125">
                            FXD Balance Before
                          </th>
                          <th scope="col" className="mw-125">
                            Amount (FXD)
                          </th>
                          <th scope="col" className="mw-125">
                            FXD Balance After
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="spacer">
                          <td colSpan={100} />
                        </tr>
                        {renderStatement}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Staking;
