import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { EthereumProvider } from "@walletconnect/ethereum-provider";

export const injected = new InjectedConnector({
  supportedChainIds: [1, 3, 4, 5, 42, 50, 51],
});

const RPC_URLS = {
  1: "https://mainnet.infura.io/v3/cbdf27ad44c843cd94282212aafd4a20",
  4: "https://rinkeby.infura.io/v3/cbdf27ad44c843cd94282212aafd4a20",
  50: "https://erpc.xinfin.network",
  51: "https://erpc.apothem.network",
};

export const walletconnect = new WalletConnectConnector({
  rpc: {
    1: RPC_URLS[1],
    4: RPC_URLS[4],
    50: RPC_URLS[50],
    51: RPC_URLS[51],
  },
  qrcode: true,
  // qrcodeModalOptions: {
  //   mobileLinks: [
  //     "rainbow",
  //     "metamask",
  //     "argent",
  //     "trust",
  //     "imtoken",
  //     "pillar",
  //     "dcent",
  //   ],
  // },
  // chainId: 50,
  // // pollingInterval: 15000,
  supportedChainIds: [50, 51],
  bridge: "https://bridge.walletconnect.org",
  infuraId: "cbdf27ad44c843cd94282212aafd4a20",
});

export const ethereumProvider = async (showQrModal, projectId) => {
  const walletConnectProvider = await EthereumProvider.init({
    projectId: projectId,
    showQrModal: showQrModal,
    qrModalOptions: {
      themeVariables: {
        "--wcm-z-index": 1050,
      },
      themeMode: "light",
      explorerRecommendedWalletIds: [
        "1ae92b26df02f0abca6304df07debccd18262fdf5fe82daa81593582dac9a369",
        "4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0",
        "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96",
        "74f8092562bd79675e276d8b2062a83601a4106d30202f2d509195e30e19673d",
        "a1f506a38f39b672b369bd13b68abbbd81f83a0489e6625f2bf12aa0389c22ae",
      ],
    },
    chains: [50],
    optionalChains: [51],
    methods: ["eth_sendTransaction", "personal_sign"],
    rpcMap: {
      1: RPC_URLS[1],
      4: RPC_URLS[4],
      50: RPC_URLS[50],
      51: RPC_URLS[51],
    },
    metadata: {
      name: "FraXn",
      description:
        "FraXn is a decentralized platform for Real Estate originators to distribute deals to wide range of bank or non-bank funders. FraXn is interoperable with a wide range of Real Estate Digitization Platforms. Connect your origination platforms to a wide network of funders & get access to the secondary market distribution marketplace. FraXn is partnering with leading brands in the blockchain space to provide outstanding solutions and services that match the standards of our fast-paced industry.",
      url: "https://fraxn.io/",
      icons: [
        "https://fraxn.io/wp-content/uploads/2024/08/fraXn-logo.png",
      ],
    },
  });
  return walletConnectProvider;
};
