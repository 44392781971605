import React, { useEffect, useState } from "react";
import {
  APIURL,
  axiosInstance,
  numberToReadable,
} from "../../helpers/constants";
import { useNavigate } from "react-router-dom";

const PoolList = () => {
  const [pools, setPools] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPools = async () => {
      const data = await axiosInstance.get("/pools");
      setPools(data.data.docs);
    };
    fetchPools();
  }, []);

  const renderPoolList = pools.map((pool, index) => {
    return (
      <tr
        className="hover-grow test-row"
        style={{ maxHeight: "50vh" }}
        key={index}
        onClick={() => {
          navigate(`/overview/${pool.simpleName}`)
        }}
        // onClick={() => {
        //   if (pool.isDeployed) window.open(pool.investmentURL, "_blank");
        //   else navigate(`/overview/${pool.simpleName}`);
        // }}
      >
        <td
          className=""
          style={{
            maxWidth: "30vh",
            minWidth: "30vw",
            cursor: "pointer",
          }}
        >
          <div className="pa-block d-flex gap-12">
            <img
              src={`${pool.logoFileName}`}
              className="pool-icon"
              alt="pool-icon"
            />
            <div className="pa-head-block media-body gap-bet-col gap-6">
              <div className="d-flex align-items-baseline justify-content-start ">
                <span className="fs-16 fw-7 fc-dark text-left flex-fill">
                  {pool.poolName}{" "}
                  <span
                    style={{
                      color:
                        pool.poolStatus === "Live"
                          ? "red"
                          : pool.poolStatus === "Fully Subscribed" ||
                            pool.poolStatus === "Completed and Repaid"
                          ? "green"
                          : "",
                    }}
                  >
                    ({pool.poolStatus})
                  </span>
                </span>
              </div>
              <div
                className="fs-12 lh-16 fc-dark fw-5 text-left text-wrap"
                style={{ backgroundColor: "", minWidth: "20vw" }}
              >
                Pool
              </div>
            </div>
          </div>
        </td>
        {/* <hr className="hr-space d-block d-md-none " /> */}
        <td className="p-sm-2 p-2">
          <div
            className="d-flex align-items-sm-center
                            justify-content-between
                            justify-content-sm-between"
          >
            <span className="text-left d-block d-md-none fc-gray item-attribute">
              Total Pool Value
            </span>
            <span
              className="cap-label fw-6"
              style={{
                position: "relative",
              }}
            >
              {pool.isDeployed
                ? numberToReadable(pool.poolValue)
                : numberToReadable(pool.poolCapacity)}{" "}
              {pool.simpleName === "yieldteq"
                ? pool.investmentTokenSymbol
                : "FXD"}
            </span>
          </div>
        </td>
        <td className="">
          <div
            className="  d-flex align-items-sm-center no-wrap text-right   
                              justify-content-between
                              gap-4 justify-content-md-end justify-content-sm-between fc-dark"
            style={{ position: "relative" }}
          >
            <span
              className="text-left d-block d-md-none fc-dark item-attribute"
              // style={{ color: '#81898f' }}
            >
              Available Pool Value
            </span>
            <span className="fs-16 fw-6">
              {pool.isDeployed
                ? numberToReadable(pool.availableValue)
                : pool.poolStatus === "Live"
                ? numberToReadable(pool.poolCapacity)
                : 0}
              <span className="fs-12 fw-7">
                {" "}
                {pool.simpleName === "yieldteq"
                  ? pool.investmentTokenSymbol
                  : "FXD"}
              </span>
            </span>
          </div>
        </td>
        <td className="p-sm-2 p-2">
          <div
            className="no-wrap d-flex align-items-sm-center gap-4  
                              justify-content-md-end justify-content-sm-between 
                              justify-content-between
                              fc-dark"
            style={{ position: "relative", minWidth: "5vw" }}
          >
            <span className="text-left d-block d-md-none fc-gray item-attribute ">
              APY
            </span>
            <span className="cap-label">
              
                {pool.apyRate} % p.a.
            </span>
          </div>
        </td>
      </tr>
    );
  });

  return (
    <div className="container-fluid">
      <div className="row col-lg-12">
        <div className="col-lg-12">
          <div className="mb-5">
          <section className="pb-0">
            <div className="row">
              <div className="col-lg-12">
                <div className="overview-head-block d-flex mb-2">
                  <h3 className="fs-18 fw-7 lh-20 fc-dark">
                  Total Value Locked: $66,272,796.17
                  </h3>
                </div>
              </div>
            </div>
            <div >
              <div className="row">
                <div className="pool-tabbed-section">
                  <div className="col-lg-12">
                    <div className="ticker-head mb-2 mb-2 fs-18 fw-7 fc-gray">
                      Name
                    </div>
                  </div>
                  <div className="col-lg-12">
                    {/* Start Table Data */}
                    <table className="table table-responsive-stack">
                      <thead>
                        <tr style={{ position: "relative" }}>
                          <th
                            className="minwidth-350 d-none d-md-block"
                            style={{ maxWidth: "30vh", minWidth: "30vw" }}
                          >
                            Name
                          </th>
                          <th className="d-none d-md-block ">Total Pool Value</th>
                          <th className="text-right mr-2 d-none d-md-block">
                            Available Pool Value
                          </th>
                          <th className="text-right d-none d-md-block">
                            APY/Rewards
                          </th>
                        </tr>
                      </thead>
                      <tbody>{renderPoolList}</tbody>
                    </table>
                    {/* End Table Data */}
                  </div>
                </div>
              </div>
            </div>
          </section>
          </div>
        </div>
      </div>
    </div>
    
  );
};

export default PoolList;
