import { Web3ReactProvider } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Layout from "./components/user/Layout";
import Home from "./pages/user/Home";
import Overview from "./pages/user/Overview";
import PoolList from "./pages/user/Poollist";
import Staking from "./pages/user/Staking";
import { w3mConnectors, w3mProvider } from "@web3modal/ethereum";

import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { mainnet, xdc, xdcTestnet } from "wagmi/chains";

const chains = [mainnet, xdc, xdcTestnet];
const projectId = process.env.REACT_APP_WALLETCONNECT_ID;

const { publicClient } = configureChains(chains, [w3mProvider({ projectId })]);
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, version: 2, chains }),
  publicClient,
});

const getLibrary = (provider) => {
  const library = new Web3Provider(provider, "any");
  library.pollingInterval = 15000;
  return library;
};

function App() {
  return (
    <>
      <WagmiConfig config={wagmiConfig}>
        <Web3ReactProvider getLibrary={getLibrary}>
          <BrowserRouter>
            <ToastContainer theme="colored" />
            <Routes>
              <Route
                path="/"
                element={
                  <Layout>
                    <Outlet />
                  </Layout>
                }
              >
                <Route path="/" element={<PoolList />} />
                <Route path="/home" element={<Home />} />
                <Route path="/overview/:pool" element={<Overview />} />
                <Route path="/staking/:pool" element={<Staking />} />
                <Route path="/staking" element={<Staking />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </Web3ReactProvider>
      </WagmiConfig>
    </>
  );
}

export default App;
