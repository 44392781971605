import React from "react";
import ScrollToTop from "./ScrollToTop";
import Header from "./Header";
import "../../assets/css/custom.css";
import "../../assets/css/remixicon/remixicon.css";
import "../../assets/css/style.css";
import "../../assets/css/responsive.css";
import Footer from "./Footer";
import NavigationBar from "./NavigationBar";

const Layout = ({ children }) => {
  return (
    <div>
      <ScrollToTop>
        <Header />
        <div className="wrapper">
          <NavigationBar />
          <div className="content-page">{children}</div>
          <Footer />
        </div>
      </ScrollToTop>
    </div>
  );
};

export default Layout;
