export const Project = "FraXn";

export const Symbol = "FXD";

export const MainWebsiteLink = "https://fraxn.io/";

export const PoolValue = "15000";

export const FathomLink = "https://dapp.fathom.fi/";

export const AssetType = "Real Estate";

export const Maturity = "1 month";

export const MaturityDays = 30;

export const APY = 12;

export const XDCAPY = 0;

export const SettlementCycle = "1 month";

export const fraXnURL =
  "https://app.fraxn.io/";

export const WhitePaperLink =
  "https://comtechgold.com/assets/whitepaper/cgo_whitepaper_v1.pdf";

export const ContactLink = "https://fraxn.io/#contact";

export const Description = `FraXn is a decentralized platform for Real Estate originators to 
distribute deals to wide range of bank or non-bank funders. FraXn is interoperable with a wide 
range of Real Estate Digitization Platforms. Connect your origination platforms to a wide network 
of funders & get access to the secondary market distribution marketplace. FraXn is partnering 
with leading brands in the blockchain space to provide outstanding solutions and services that match 
the standards of our fast-paced industry.`;

export const ContactEmail = "tech@fraxn.io";

export const TotalSupply = "100000";

export const StakingSD = `Stake ${Symbol} to get more ${Symbol} rewards. The longer the lock period 
- the more rewards.`;

export const EarlyFees = "N.A.";

export const minStake = 100;

export const maxStake = 25000;

export const stakingContractAddress =
  "0x46bB4Ba9eCfFeB36330F14F044dFC4a7eD9dA69F";

export const tokenContractAddress =
  "0x49d3f7543335cf38fa10889ccff10207e22110b5";

export const stakingContractAddressAPO =
   "0x54858B4b2541A6158Be90Eb4d74E4849c9e9C129"; //"0x6458422005D01122f1bd3f28Ce13bFeA30099699";

export const tokenContractAddressAPO =
  "0xab421538383e7d5652f1807fb0a7a19d178a3143"; //0x0546c02e08E6bcBA9f972D85c0531697401F29aA

export const tokenPairAddress = "0x4821cDc4b01BFee77C6ab8aBc9348c4351600e26";

export const USDTTokenAddress = "0xd4b5f10d61916bd6e0860144a91ac658de8a1437";

export const mainnetRPC = "https://erpc.xinfin.network";

export const mainnetARPC = "https://earpc.xinfin.network";

export const apothemRPC = "https://erpc.apothem.network";

export const apothemARPC = "https://rpc.apothem.network";
//export const apothemARPC = "https://earpc.apothem.network";

export const stakingContractBlockNumber = "61560500";

export const smartContractLink =
  "https://xdc.network/address/xdc46bb4ba9ecffeb36330f14f044dfc4a7ed9da69f#readContract";
