import axios from "axios";

export const APIURL = process.env.REACT_APP_API_URL;
export const toastTime = 3000;

export const postConfig = {
  headers: { "Content-Type": "application/x-www-form-urlencoded" },
};

export const formDataConfig = {
  headers: { "Content-Type": "application/form-data" },
};

export const multiPartConfig = {
  headers: { "Content-Type": "multipart/form-data" },
  cache: false,
};

export const axiosInstance = axios.create({
  baseURL: APIURL,
  headers: {
    "Content-Type": "application/json",
  },
});

export function getJWT() {
  const jwt = localStorage.getItem("jwt");
  return jwt;
}

export const numberToReadable = (labelValue) => {
  return Math.abs(Number(labelValue)) >= 1.0e9
    ? parseFloat(Math.abs(labelValue) / 1.0e9, 2).toFixed(2) + "B"
    : // Six Zeroes for Millions
    Math.abs(Number(labelValue)) >= 1.0e6
    ? parseFloat(Math.abs(labelValue) / 1.0e6, 2).toFixed(2) + "M"
    : // Three Zeroes for Thousands
      Math.abs(Number(labelValue)).toLocaleString("en-US");
};
