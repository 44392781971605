// AppContext.js
import { createContext, useContext, useState } from "react";

const AppContext = createContext();

export const useAppContext = () => useContext(AppContext);

export const AppContextProvider = ({ children }) => {
  const [walletAddress, setWalletAddress] = useState("");
  const [chain, setChain] = useState(51);
  const [connected, setConnected] = useState(false);
  const [connection, setConnection] = useState("");

  return (
    <AppContext.Provider
      value={{
        walletAddress,
        setWalletAddress,
        chain,
        setChain,
        connected,
        setConnected,
        connection,
        setConnection,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
