import React from "react";
import { Project, MainWebsiteLink } from "../../helpers/data";

const Footer = () => {
  return (
    <footer className="iq-footer">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 text-center">
            <p>
              Copyright 2023{" "}
              <a href={MainWebsiteLink} target="_blank" rel="noreferrer">
                {Project}
              </a>
              . All Rights Reserved.
            </p>
            <p>
              Leveraging the pioneering tokenization technology developed by{" "}
              <a href="https://fraxn.io/" target="_blank" rel="noreferrer">
                FraXn
              </a>{" "}
              using{" "}
              <a href="https://xinfin.org" target="_blank" rel="noreferrer">
                XDC Network
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
