import React, { useEffect, useState } from "react";
import Logo from "../../assets/images/logo.png";
import LogoWhite from "../../assets/images/logo-white.png";
import { Modal } from "react-bootstrap";
import XDCPay from "../../assets/images/xdcpay.svg";
import Metamask from "../../assets/images/metamask.svg";
import WalletConnect from "../../assets/images/wallet-connect.svg";
import $ from "jquery"; // Import jQuery
import { Link, useLocation } from "react-router-dom";
import {
  FathomLink,
  ContactLink,
  fraXnURL,
  mainnetRPC,
  tokenContractAddress,
  USDTTokenAddress,
  tokenPairAddress,
  apothemRPC,
} from "../../helpers/data";
import { useWeb3React } from "@web3-react/core";
import { ethereumProvider, injected } from "../../wallet/connectors";
import Web3 from "web3";
import { toast } from "react-toastify";
import { toastTime } from "../../helpers/constant";
import xrc20ABI from "../../wallet/TokenAbi.json";
import { useAppContext } from "../../AppContext";

const NavigationBar = () => {
  const { account, active, chainId, library, activate, deactivate } =
    useWeb3React();
  const {
    walletAddress,
    setWalletAddress,
    chain,
    setChain,
    connected,
    setConnected,
    connection,
    setConnection,
  } = useAppContext();
  const [FXDPrice, setFXDPrice] = useState(0);
  // const [walletAddress, setWalletAddress] = useState(undefined);
  // const [walletBalance, setWalletBalance] = useState(undefined);
  const [isWalletConnected, setIsWalletConnected] = useState(false);

  async function connect() {
    try {
      await activate(injected);
      localStorage.setItem("isWalletConnected", true);
      localStorage.setItem("wallet", "injected");
      setConnected(true);
      setConnection("injected");
      // setWalletAddress(account);
      // setChain(chainId);
      handleClose();
    } catch (ex) {
      console.log("connection error", ex);
    }
  }

  /* updated by satish for wallet connect  */
  async function connectWallet() {
    try {
      handleClose();
      const walletConnectProvider = await ethereumProvider(
        true,
        process.env.REACT_APP_WALLETCONNECT_ID
      );
      await walletConnectProvider.connect();
      walletConnectProvider.on("disconnect", (code, reason) => {
        localStorage.clear();
        disconnect();
      });

      localStorage.setItem("isWalletConnected", true);
      localStorage.setItem("wallet", "walletConnect");
      // console.log("log_wallet", walletConnectProvider);
      setIsWalletConnected(true);
      setConnected(true);
      setConnection("walletConnect");
      setWalletAddress(walletConnectProvider.accounts[0]);
      setChain(walletConnectProvider.chainId);
      walletConnectProvider.on("chainChanged", (code, reason) => {
        setChain(walletConnectProvider.chainId);
      });
      walletConnectProvider.on("accountsChanged", (code, reason) => {
        setWalletAddress(walletConnectProvider.accounts[0]);
      });
    } catch (ex) {
      console.log("connection error", ex);
    }
  }

  async function disconnect() {
    try {
      // const walletType = localStorage.getItem("wallet");
      const walletType = connection;
      if (walletType === "walletConnect") {
        const ewjs = await ethereumProvider(
          false,
          process.env.REACT_APP_WALLETCONNECT_ID
        );

        await ewjs.disconnect();
      }
      if (walletType === "injected") {
        deactivate();
      }
      localStorage.clear();
      setIsWalletConnected(false);
      setWalletAddress(undefined);
      setConnected(false);
      // setWalletBalance(undefined);
    } catch (ex) {
      console.log(ex);
    }
  }

  useEffect(() => {
    if (connected && connection === "injected") {
      setChain(chainId);
      setWalletAddress(account);
    }
  }, [chainId, connected, connection, account, setChain, setWalletAddress]);

  useEffect(() => {
    let isMounted = true;
    // const updateFXDPrice = async () => {
    //   const web3 = new Web3(new Web3.providers.HttpProvider(mainnetRPC));
    //   const FXDContract = new web3.eth.Contract(xrc20ABI, tokenContractAddress);
    //   const USDTContract = new web3.eth.Contract(xrc20ABI, USDTTokenAddress);
    //   let bal = await FXDContract.methods.balanceOf(tokenPairAddress).call();
    //   const tokenBal = parseFloat(
    //     parseFloat(web3.utils.fromWei(bal, "ether")).toFixed(4)
    //   );
    //   bal = await USDTContract.methods.balanceOf(tokenPairAddress).call();
    //   const usdtBal = parseFloat(parseFloat(bal / 10 ** 6).toFixed(4));
    //   isMounted &&
    //     setFXDPrice(parseFloat(parseFloat(usdtBal / tokenBal).toFixed(4)));
    // };

    // updateFXDPrice(); // Call the function initially

    // const interval = setInterval(() => {
    //   updateFXDPrice(); // Call the function at the specified interval
    // }, 300000);

    return () => {
      //clearInterval(interval); // Clear the interval when the component is unmounting
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (connected) {
      if (chain === 50 || chain === 51) {
        setWalletAddress(account);
      } else {
        toast.error(
          `Please Switch Network to XDC mainnet or apothem or Please reconnect.`,
          {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: toastTime,
          }
        );
      }
    }
  }, [connected, chain, account, setWalletAddress]);

  useEffect(() => {
    const connectWalletOnPageLoad = async () => {
      if (
        // localStorage?.getItem("isWalletConnected") === "true" &&
        // localStorage.getItem("wallet") !== "walletConnect"
        connected === "true" &&
        connection !== "walletConnect"
      ) {
        try {
          await activate(injected);
          localStorage.setItem("isWalletConnected", true);
          setConnected("true");
          setConnection("injected");
          // setWalletAddress(account);
          // setChain(chainId);
        } catch (ex) {
          console.log(ex);
        }
      }
      if (
        // localStorage?.getItem("isWalletConnected") === "true" &&
        // localStorage.getItem("wallet") === "walletConnect"
        connected === "true" &&
        connection === "walletConnect"
      ) {
        const ewjs = await ethereumProvider(
          false,
          process.env.REACT_APP_WALLETCONNECT_ID
        );
        setWalletAddress(ewjs.accounts[0]);
        setIsWalletConnected(true);
        setConnected("true");
        setConnection("walletConnect");
        setChain(ewjs.chainId);
      }
    };
    connectWalletOnPageLoad();
  }, [
    activate,
    setWalletAddress,
    account,
    chainId,
    connected,
    connection,
    setChain,
    setConnected,
    setConnection,
  ]);

  useEffect(() => {
    async function fetchAccountBalance() {
      try {
        const web3 = new Web3(
          new Web3.providers.HttpProvider(
            chain === 50 ? mainnetRPC : apothemRPC
          )
        );
        const balance = await web3.eth.getBalance(walletAddress);
        const etherBalance = web3.utils.fromWei(balance, "ether");
        // setWalletAddress(account);
        // setWalletBalance(etherBalance);
        // setIsWalletConnected(true);
        console.log("Account Balance:", etherBalance);
      } catch (error) {
        console.error("Error fetching account balance:", error);
      }
    }
    if (walletAddress && chain) {
      fetchAccountBalance();
    }
  }, [walletAddress, chain]);

  const location = useLocation();
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  useEffect(() => {
    // jQuery code
    $(document).on(
      "change",
      '.change-mode input[type="checkbox"]',
      function (e) {
        const dark = $(this).prop("checked");
        changeMode(dark);
      }
    );

    function changeMode(dark) {
      const body = $("body");
      updateLocalStorage(dark);
      const switchInput = $("#dark-mode");
      if (dark) {
        // Add a class to enable smooth transition
        body.addClass("transition-dark");
        // Wait for the transition to complete and then remove the class
        setTimeout(() => {
          body.removeClass("transition-dark");
        }, 800); // Adjust the duration (in milliseconds) to match your CSS transition
        $('[data-mode="toggle"]').find("i.a-right").removeClass("ri-sun-line");
        $('[data-mode="toggle"]')
          .find("i.a-left")
          .addClass("ri-moon-clear-line");
        $(".darkmode-logo").removeClass("d-none");
        $(".light-logo").addClass("d-none");
        body.addClass("dark");
        switchInput.prop("checked", true).attr("data-active", "false");
      } else {
        // Add a class to enable smooth transition
        body.addClass("transition-light");
        // Wait for the transition to complete and then remove the class
        setTimeout(() => {
          body.removeClass("transition-light");
        }, 800); // Adjust the duration (in milliseconds) to match your CSS transition
        $('[data-mode="toggle"]')
          .find("i.a-left")
          .removeClass("ri-moon-clear-line");
        $('[data-mode="toggle"]').find("i.a-right").addClass("ri-sun-line");
        $(".light-logo").removeClass("d-none");
        $(".darkmode-logo").addClass("d-none");
        body.removeClass("dark");
        switchInput.prop("checked", false).attr("data-active", "true");
      }
      const event = new CustomEvent("ChangeColorMode", {
        detail: { dark: dark },
      });
      document.dispatchEvent(event);
    }

    function updateLocalStorage(dark) {
      sessionStorage.setItem("dark", dark);
    }

    const urlParams = new URLSearchParams(window.location.search);
    const storageDark = sessionStorage.getItem("dark");
    const myParam = urlParams.get("dark");

    if (myParam !== null) {
      if (myParam === "true") {
        changeMode(true);
      } else {
        changeMode(false);
      }
    } else {
      if (storageDark === null) {
        changeMode(true);//changeMode(false);
      } else {
        changeMode(storageDark === "true");
      }
    }
  }, []); // Empty dependency array to run the effect only once

  useEffect(() => {
    const handleScroll = () => {
      const navbar = document.querySelector(".iq-top-navbar");
      const whiteBgMenu = document.querySelector(".white-bg-menu");

      if (navbar && whiteBgMenu) {
        if (window.scrollY > 0) {
          navbar.classList.add("fixed");
          whiteBgMenu.classList.add("sticky-menu");
        } else {
          navbar.classList.remove("fixed");
          whiteBgMenu.classList.remove("sticky-menu");
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleWrapperMenuClick = () => {
      $(".wrapper-menu").toggleClass("open");
      $("body").toggleClass("sidebar-main");
    };

    $(document).on("click", ".wrapper-menu", handleWrapperMenuClick);

    return () => {
      $(document).off("click", ".wrapper-menu", handleWrapperMenuClick);
    };
  }, []);

  useEffect(() => {
    const handleCollapseHide = () => {
      $(".h-collapse.navbar-collapse").collapse("hide");
    };

    $(document).on("click", ".close-toggle", handleCollapseHide);

    return () => {
      $(document).off("click", ".close-toggle", handleCollapseHide);
    };
  }, []);

  useEffect(() => {
    const activateParents = () => {
      const parents = $("li.active").parents(".iq-submenu.collapse");
      parents.addClass("show");
      parents.parents("li").addClass("active");
      $('li.active > a[aria-expanded="false"]').attr("aria-expanded", "true");
    };

    activateParents();

    return () => {
      // Cleanup code if necessary
    };
  }, []);

  return (
    <>
      <div className="iq-sidebar sidebar-default">
        <div className="iq-sidebar-logo d-flex align-items-center justify-content-between">
          <Link to="/" className="header-logo">
            <img
              src={Logo}
              className="img-fluid rounded-normal light-logo"
              alt="logo"
            />
            <img
              src={LogoWhite}
              className="img-fluid rounded-normal darkmode-logo"
              alt="logo"
            />
          </Link>
          <div className="iq-menu-bt-sidebar">
            <i className="ri-close-line wrapper-menu" />
          </div>
        </div>
        <div className="data-scrollbar" data-scroll={1}>
          <nav className="iq-sidebar-menu">
            <ul id="iq-sidebar-toggle" className="iq-menu">
              <li
                className={
                  location.pathname.toLowerCase() === "/" ? "active" : ""
                }
              >
                <Link to="/" className="">
                  <i className="ri-home-4-line" />
                  <span>HOME</span>
                </Link>
              </li>
              {/* <li
                className={
                  location.pathname.toLowerCase() === "/staking" ? "active" : ""
                }
              >
                <Link to="/staking" className="">
                  <i className="ri-coins-fill" />
                  <span>STAKING</span>
                </Link>
              </li> */}
              <li>
                <a
                  href={fraXnURL}
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="ri-book-open-line" />
                  <span>MARKETPLACE</span>
                </a>
              </li>
              <li>
                <a href={ContactLink} target="_blank" rel="noreferrer">
                  <i className="ri-mail-line" />
                  <span>CONTACT US</span>
                </a>
              </li>
            </ul>
          </nav>
          <div className="p-3" />
        </div>
      </div>

      <div className="iq-top-navbar">
        <div className="iq-navbar-custom">
          <nav className="navbar navbar-expand-lg navbar-light">
            <div className="iq-navbar-logo d-flex align-items-center justify-content-between">
              <i className="ri-menu-fill wrapper-menu" />
              <Link to="/" className="header-logo">
                <img src={Logo} className="img-fluid light-logo" alt="logo" />
                <img
                  src={LogoWhite}
                  className="img-fluid darkmode-logo"
                  alt="logo"
                />
              </Link>
            </div>
            <div className="cta-desktop d-flex gap-20">
              <div className="price d-flex gap-6">
                {/* <div className="fs-12 fw-7">FXD PRICE:</div>
                <div className="fs-16 fw-8">$ {FXDPrice}</div> */}
              </div>
              <div className="buy">
                {/* <a
                  href={FathomLink}
                  className="fw-6"
                  target="_blank"
                  rel="noreferrer"
                >
                  BUY FXD
                </a> */}
              </div>
            </div>
            <div className="d-flex align-items-center">
              {/* <div className="change-mode">
                <div className="custom-control custom-switch custom-switch-icon custom-control-inline">
                  <div className="custom-switch-inner">
                    <p className="mb-0" />
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="dark-mode"
                      data-active="true"
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="dark-mode"
                      data-mode="toggle"
                    >
                      <span className="switch-icon-left">
                        <i className="a-left" />
                      </span>
                      <span className="switch-icon-right">
                        <i className="a-right" />
                      </span>
                    </label>
                  </div>
                </div>
              </div> */}
              {connected ? (
                <>
                  {" "}
                  {walletAddress?.substring(0, 4)}...
                  {walletAddress?.substring(walletAddress?.length - 4)}{" "}
                  <button
                    className="wallet-connect"
                    type="button"
                    onClick={disconnect}
                  >
                    <i className="ri-logout-box-r-fill" />
                  </button>
                </>
              ) : (
                <button
                  className="wallet-connect"
                  type="button"
                  data-toggle="modal"
                  data-target="#centermodal"
                  onClick={handleShow}
                >
                  <i className="ri-wallet-3-fill" />
                </button>
              )}
            </div>
          </nav>
        </div>
      </div>

      <Modal
        show={showModal}
        onHide={handleClose}
        className="modal fade"
        id="centermodal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        dialogClassName="modal-dialog modal-dialog-centered"
        contentClassName="modal-content"
      >
        {/* <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content"> */}
        <Modal.Header className="modal-header border-bottom-0">
          <Modal.Title className="modal-title" id="exampleModalLabel">
            Connect your Wallet
          </Modal.Title>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={handleClose}
          >
            <span aria-hidden="true">×</span>
          </button>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <div className="wallet-connect-block">
            <button className="wallect-connect-btn" onClick={connect}>
              <div className="wallet-name">
                <h4>XDC Pay</h4>
              </div>
              <div className="wallet-icon">
                <img src={XDCPay} alt="xdcpay" />
              </div>
            </button>
            <button className="wallect-connect-btn" onClick={connect}>
              <div className="wallet-name">
                <h4>Metamask</h4>
              </div>
              <div className="wallet-icon">
                <img src={Metamask} alt="metamask" />
              </div>
            </button>
            <button className="wallect-connect-btn" onClick={connectWallet}>
              <div className="wallet-name">
                <h4>Wallet Connect</h4>
              </div>
              <div className="wallet-icon">
                <img src={WalletConnect} alt="Wallet Connect" />
              </div>
            </button>
          </div>
        </Modal.Body>
        {/* </div>
        </div> */}
      </Modal>
    </>
  );
};

export default NavigationBar;
