import React, { useEffect, useState } from "react";
import TokenIcon from "../../assets/images/pool-assets/token-icon.png";
import TokenLogo from "../../assets/images/pool-assets/token-logo.png";
import FXDIcon from "../../assets/images/pool-assets/fxd-icon.svg";
import {
  APY,
  AssetType,
  Project,
  ContactEmail,
  Description,
  FathomLink,
  MainWebsiteLink,
  Maturity,
  PoolValue,
  SettlementCycle,
  fraXnURL,
  XDCAPY,
  mainnetRPC,
  tokenContractAddress,
  USDTTokenAddress,
  tokenPairAddress,
  stakingContractAddress,
  stakingContractAddressAPO,
  smartContractLink,
  apothemRPC,
} from "../../helpers/data";
import { Link } from "react-router-dom";
import { numberToReadable } from "../../helpers/constant";
import Web3 from "web3";
import xrc20ABI from "../../wallet/TokenAbi.json";
import stakingABI from "../../wallet/StakingAbi.json";
import { useWeb3React } from "@web3-react/core";
import { useAppContext } from "../../AppContext";

const Home = () => {
  const { active, library, chainId } = useWeb3React();
  const { chain, connected } = useAppContext();
  const [FXDPrice, setFXDPrice] = useState(0);
  const [apy, setApy] = useState(APY);
  const [web3, setWeb3] = useState();
  const [stakingContractInst, setStakingContractInst] = useState();
  const [totalPoolValue, setTotalPoolValue] = useState();
  const [availablePoolValue, setAvailablePoolValue] = useState();

  const updateFXDPrice = async () => {
    const web3 = new Web3(new Web3.providers.HttpProvider(mainnetRPC));
    const FXDContract = new web3.eth.Contract(xrc20ABI, tokenContractAddress);
    const USDTContract = new web3.eth.Contract(xrc20ABI, USDTTokenAddress);
    let bal = await FXDContract.methods.balanceOf(tokenPairAddress).call();
    const tokenBal = parseFloat(
      parseFloat(web3.utils.fromWei(bal, "ether")).toFixed(4)
    );
    bal = await USDTContract.methods.balanceOf(tokenPairAddress).call();
    const usdtBal = parseFloat(parseFloat(bal / 10 ** 6).toFixed(4));
    setFXDPrice(parseFloat(parseFloat(usdtBal / tokenBal).toFixed(4)));
  };

  // updateFXDPrice();
  // setInterval(() => {
  //   updateFXDPrice();
  // }, 300000);

  useEffect(() => {
    if (connected) {
      const web3Inst = new Web3(
        new Web3.providers.HttpProvider(chain === 50 ? mainnetRPC : apothemRPC)
      );
      setWeb3(web3Inst);
      setStakingContractInst(
        new web3Inst.eth.Contract(
          stakingABI,
          chain === 50 ? stakingContractAddress : stakingContractAddressAPO
        )
      );
    } else {
      const web3Inst = new Web3(new Web3.providers.HttpProvider(mainnetRPC));
      setWeb3(web3Inst);
      setStakingContractInst(
        new web3Inst.eth.Contract(stakingABI, stakingContractAddress)
      );
    }
  }, [chain, connected]);

  useEffect(() => {
    let isMounted = true;
    if (web3 && stakingContractInst) {
      const updateData = async () => {
        const interest = parseInt(
          await stakingContractInst.methods.interest().call()
        );
        const interestPrecision = parseInt(
          await stakingContractInst.methods.interestPrecision().call()
        );
        isMounted && setApy(interest / interestPrecision);
        const maxPoolAmount = parseFloat(
          parseFloat(
            web3.utils.fromWei(
              await stakingContractInst.methods.maxPoolAmount().call(),
              "ether"
            )
          ).toFixed(4)
        );
        isMounted && setTotalPoolValue(maxPoolAmount);
        const pendingPoolAmount = parseFloat(
          parseFloat(
            web3.utils.fromWei(
              await stakingContractInst.methods.pendingPoolAmount().call(),
              "ether"
            )
          ).toFixed(4)
        );
        const expiryTime =
          parseInt(await stakingContractInst.methods.expiryTime().call()) *
          1000;
        const expired = new Date() >= new Date(expiryTime);
        isMounted && setAvailablePoolValue(expired ? 0 : pendingPoolAmount);
      };
      updateData();
    }
    return () => {
      isMounted = false;
    };
  }, [stakingContractInst, web3]);

  return (
    <div className="container-fluid">
      <div className="row overflow-block">
        <div className="col-lg-12">
          <div className="overflow-block-bg mb-5">
            <div className="cta-mobile d-flex gap-20">
              <div className="price d-flex gap-6">
                {/* <div className="fs-12 fw-7">FXD PRICE:</div>
                <div className="fs-16 fw-8">$ {FXDPrice}</div> */}
              </div>
              <div className="buy">
                {/* <a
                  href={FathomLink}
                  className="fw-6"
                  target="_blank"
                  rel="noreferrer"
                >
                  BUY FXD
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="overview-head-block d-flex mb-3">
            <div className="d-flex">
              <img src={TokenIcon} className="pool-icon" alt="token-icon" />
              <div className="overview-head media-body gap-bet-col gap-0">
                <div className="tophead d-flex gap-18">
                  <span className="fs-16 fc-dark">{Project}</span>
                  <span className="cap-label">
                    {numberToReadable(totalPoolValue)} FXD
                  </span>
                </div>
                <p className="fs-16 fw-7 lh-16 fc-dark">Overview</p>
              </div>
            </div>
            <div className="d-none d-sm-block d-md-block d-lg-block">
              <Link to="/staking" className="fw-8">
                <i className="ri-coins-fill" />
                Stake Now
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-1 justify-content-center">
        <div className="col-sm-12 col-md-12 col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="row stats-counter">
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-3 ver-divider ver-divider_none-lg ver-divider_none-md hor-divider hor-divider_none-xl hor-divider_none-md mb-lg-4 mb-xl-0">
                  <div className="media-body text-center tvl-gap-bet-col gap-6 align-items-center">
                    <div className="d-flex tvl-header no-wrap gap-6 mb-0">
                      <div className="no-wrap">
                        <span className="fs-16 fw-6 fc-dark">{AssetType}</span>
                      </div>
                    </div>
                    <p className="fs-13 fc-light">Asset type</p>
                  </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-3 col-xl-2 ver-divider ver-divider_none-md">
                  <div className="media-body text-center tvl-gap-bet-col gap-6 align-items-center">
                    <div className="d-flex tvl-header no-wrap mb-0">
                      <div className="no-wrap">
                        <span className="fs-16 fw-6 fc-dark">{Maturity}</span>
                      </div>
                    </div>
                    <p className="fs-13 fc-light">Asset maturity</p>
                  </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-3 col-xl-2 ver-divider ver-divider_none-md">
                  <div className="media-body text-center tvl-gap-bet-col gap-6 align-items-center">
                    <div className="d-flex tvl-header no-wrap gap-6 mb-0">
                      {/*<div className="stats-icon"><img src="assets/images/pool-assets/drop-icon.svg"></div>*/}
                      <div className="no-wrap">
                        <span className="fs-16 fw-6 fc-dark">{apy}</span>
                        <span className="fs-12 fc-dark">% P.A.</span>
                      </div>
                    </div>
                    <p className="fs-13 fc-light">APY</p>
                  </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-3 col-xl-2 ver-divider ver-divider_none-md">
                  <div className="media-body text-center tvl-gap-bet-col gap-6 align-items-center">
                    <div className="d-flex tvl-header no-wrap gap-6 mb-0">
                      <div className="no-wrap">
                        <span className="fs-16 fw-6 fc-dark">
                          {SettlementCycle}
                        </span>
                      </div>
                    </div>
                    <p className="fs-13 fc-light">Settlement cycle</p>
                  </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-3 col-xl-3 ver-divider ver-divider_none-md">
                  <div className="media-body text-center tvl-gap-bet-col gap-6 align-items-center">
                    <div className="d-flex tvl-header no-wrap gap-6 mb-0">
                      <div className="stats-icon">
                        <img src={FXDIcon} alt="fxd-icon" />
                      </div>
                      <div className="no-wrap">
                        <span className="fs-16 fw-6 fc-dark">
                          {totalPoolValue}
                        </span>
                        <span className="fs-12 fc-dark">FXD</span>
                      </div>
                    </div>
                    <p className="fs-13 fc-light">Total Pool value</p>
                  </div>
                </div>
              </div>
              {/* end row */}
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-lg-12">
          <div className="overview-head-block d-flex mb-2">
            <h3 className="fs-18 fw-7 lh-20 fc-dark">
              Asset Originator Details
            </h3>
          </div>
        </div>
      </div>
      <div className="row mt-1">
        <div className="col-sm-12 col-md-12 col-lg-12">
          <div className="card">
            <div className="card-body ao-details gap-24 d-flex">
              <div className="ao-logo">
                <img src={TokenLogo} alt="token-logo" />
              </div>
              <p className="fs-14 mb-0">{Description}</p>
              <div className="ao-footer gap-20">
                <div className="issuer d-flex">
                  <p className="fs-13 fc-light mb-0">Issuer</p>
                  <div className="issuer-name fs-14 fw-6 fc-dark">
                    {Project}
                  </div>
                </div>
                <div className="link d-flex">
                  <p className="fs-13 fc-light mb-0">Links</p>
                  <div className="link-name d-flex fs-14 fw-6 fc-dark">
                    <a
                      href={fraXnURL}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="ri-book-open-line" /> MarketPlace
                    </a>
                    <a href={MainWebsiteLink} target="_blank" rel="noreferrer">
                      <i className="ri-global-line" /> Website
                    </a>
                    <a href={`mailto:${ContactEmail}`}>
                      <i className="ri-mail-line" /> Contact Issuer
                    </a>
                    <a
                      href={smartContractLink}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="ri-file-edit-line" />
                      Smart Contract
                    </a>
                    <Link to="/staking" className="fw-8">
                      <i className="ri-coins-fill" />
                      Stake Now
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-lg-12">
          <div className="overview-head-block d-flex mb-2">
            <h3 className="fs-18 fw-7 lh-20 fc-dark">Status</h3>
          </div>
        </div>
      </div>
      <div className="row mt-1 mb-5">
        <div className="col-sm-12 col-md-6 col-lg-6">
          <div className="card h100 mb-0">
            <div className="card-body">
              <h3 className="fs-16 fw-7 lh-20 mt-0 mb-3">Asset Details</h3>
              <div className="ao-details gap-12 d-flex mb-0">
                <div className="d-flex justify-content-between">
                  <span className="fw-7">Token Price</span>
                  <span>{FXDPrice} USD</span>
                </div>
                <hr className="hr-space" />
                <div className="d-flex justify-content-between">
                  <span className="fw-7">Available Pool</span>
                  <span>{numberToReadable(availablePoolValue)}</span>
                </div>
                <hr className="hr-space" />
                <div className="d-flex justify-content-between">
                  <span className="fw-7">Total Pool</span>
                  <span>{numberToReadable(totalPoolValue)}</span>
                </div>
                <hr className="hr-space" />
                <div className="d-flex justify-content-between">
                  <span className="fw-7">Blockchain Network</span>
                  <span>XDC Network</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-6 col-lg-6 mt-4 mt-md-0 mt-lg-0">
          <div className="card h100 mb-0">
            <div className="card-body">
              <h3 className="d-flex gap-8 fs-16 fw-7 lh-20 mt-0 mb-3">
                {/*<div className="stats-icon d-none d-sm-block"><img src="assets/images/pool-assets/drop-icon.svg"></div>*/}
                Reward Details
              </h3>
              <div className="ao-details gap-12 d-flex">
                <div className="d-flex justify-content-between">
                  <span className="fw-7">FXD</span>
                  <div className="d-flex gap-4">
                    <span>{apy}</span>
                    <span>% P.A.</span>
                  </div>
                </div>
                <hr className="hr-space" />
                <div className="d-flex justify-content-between">
                  <span className="fw-7">Reward Token</span>
                  <div className="d-flex gap-4">
                    <span>{XDCAPY}</span>
                    <span>% P.A.</span>
                  </div>
                </div>
                <hr className="hr-space" />
                <div className="w-7 d-flex justify-content-between">
                  <span className="fw-7">Total Rewards</span>
                  <div className="d-flex gap-4">
                    <span>{apy + XDCAPY}</span>
                    <span>% P.A.</span>
                  </div>
                </div>
                <hr className="hr-space" />
                <div className="d-flex justify-content-between">
                  <span className="fw-7">Maturity Cycle</span>
                  <div className="d-flex gap-4">
                    <span>{SettlementCycle}</span>
                  </div>
                </div>
                <hr className="hr-space" />
                <div className="d-flex justify-content-center">
                  <Link to="/staking" className="fw-8">
                    <i className="ri-coins-fill" />
                    Stake Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
